import React from "react"
import { graphql } from "gatsby"
import * as containerStyles from "./eula.module.css"

const EulaPage = ({ data: { wpPage } }) => {
  return (
    <div className="bg-gray-50 py-8">
      <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 mr-2 text-center">
        {wpPage.title}
      </h1>
      <div
        className={`bg-white max-w-5xl shadow-lg p-8 my-8 mx-auto rounded-lg text-gray-600 ${containerStyles.eulaContent}`}
        dangerouslySetInnerHTML={{ __html: wpPage.content }}
      ></div>
    </div>
  )
}

export default EulaPage

export const query = graphql`
  query {
    wpPage(databaseId: { eq: 384 }) {
      title
      content
    }
  }
`
